import React from "react"
import { Link } from "gatsby"
import { Flex, Box } from "rebass"
import Figure from "../components/Figure"
import { H1, H3, P, Ul } from "../components/VerticalRhythm"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import RespGrid from "../components/RespGrid"
import breakpoints from "../theme/breakpoints"

import PictureSet from "../components/PictureSet"

const DataProtectionPage = () => (
  <Layout>
    <SEO title="Datenschutz" />
    <Figure id={"master"} caption="">
      {/* <PictureSet imgObj={} /> */}
    </Figure>
    <Section>
      <Link to="/">← Zahnräuber</Link>
      <H1 mb={3}>Datenschutz</H1>
      <P>
        Karl Anders
        <br />
        Stüttgerhofweg 4f
        <br />
        50858 Köln
        <br />
        info@zahnraeuber.de
      </P>
      <P>
        Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer
        berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten
        über jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet
        (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der
        abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene
        Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst Version,
        das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite),
        IP-Adresse und der anfragende Provider.
      </P>
      <P>
        Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung
        von Missbrauchs- oder Betrugshandlungen) für die Dauer von maximal 7
        Tagen gespeichert und danach gelöscht. Daten, deren weitere Aufbewahrung
        zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des
        jeweiligen Vorfalls von der Löschung ausgenommen.
      </P>
      <P>
        Diese Seite erhebt und verfolgt selbst keine Nutzerdaten und setzt auch
        keine Analyse-Tools wie Google-Analytics ein. Jedoch wird diese Seite
        auf{" "}
        <a
          href="https://www.netlify.com/"
          rel="nofollow"
          rel="noreferrer"
          target="_blank"
        >
          https://www.netlify.com/
        </a>{" "}
        gehostet. Details zum Datenschutz von Netlify entnehmen Sie bitte{" "}
        <a
          href="https://www.netlify.com/gdpr/"
          rel="nofollow"
          rel="noreferrer"
          target="_blank"
        >
          https://www.netlify.com/gdpr/
        </a>
        . Vielen Dank.
      </P>

      <P>
        <em>
          Erstellt mit Datenschutz-Generator.de von RA Dr. jur. Thomas Schwenke
        </em>
      </P>
      <div style={{ height: "100px" }}></div>
    </Section>
  </Layout>
)

export default DataProtectionPage
